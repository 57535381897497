import { useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL, ENDPOINT_LOGIN } from "../../constants/APIConstant";
import { appContext } from "../../context/context";

const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const { saveToken } = useContext(appContext);

  const login = async (username, password, rememberMe) => {
    const id = toast.loading("Logging in...");
    setLoading(true);
    try {
      const response = await axios.post(BASE_URL + ENDPOINT_LOGIN, {
        userName: username,
        password,
      });
      const token = response.data;

      localStorage.setItem(
        "credentials",
        JSON.stringify({ username, password })
      );
      if (rememberMe) {
        localStorage.setItem("autoLogin", JSON.stringify(true));
      } else {
        localStorage.removeItem("autoLogin");
      }

      toast.update(id, {
        render: "Login successful",
        type: "success",
        isLoading: false,
        autoClose: 1000,
        hideProgressBar: true
      });
      saveToken(token);
    } catch (error) {
      toast.update(id, {
        render: "Login failed",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    } finally {
      setLoading(false);
    }
  };

  return { login, loading };
};

export default useLogin;
