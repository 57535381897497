import React, { useState, useEffect } from "react";
import editIcon from "../../../assets/svgs/pencil-writing-blue-circle-20625.svg";
import disabledEditIcon from "../../../assets/svgs/disabled-pencil-writing-blue-circle-20625.svg";
import useAddCategory from "../../../hooks/staticQuiz/useAddCategory";
import useUpdateCategory from "../../../hooks/staticQuiz/useUpdateCategory";
import useGetAllCategories from "../../../hooks/staticQuiz/useGetAllCategories";
import {
  DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_ACTIVE,
  DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_INACTIVE,
  DAILY_QUIZ_CATEGORY_STATUS_CONSTANTS,
} from "../../../constants/DailyQuizCategoryStatusConstants";
import plusIcon from "../../../assets/svgs/green-plus-11975.svg";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { getAllCategories, fetchingCategories } = useGetAllCategories();
  // eslint-disable-next-line
  const { addCategory, saving } = useAddCategory();
  const { updateCategory, updating } = useUpdateCategory();

  const fetchCategories = () =>
    getAllCategories((data) => {
      setCategories(data);
    });

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddCategory = () => {
    const name = prompt("Please Category Name: ");
    if (name && name.trim().length > 0) {
      if (
        categories.some(
          (category) => category?.name.toLowerCase() === name.toLowerCase()
        )
      ) {
        alert("Category Exists.");
      } else {
        // Add Category
        addCategory(name, fetchCategories);
      }
    } else {
      alert("Invalid Name.");
    }
  };

  const headers = ["Id", "Question", "Status", "Operations"];

  const headerClass =
    "px-4 py-2 border-b-2 border-gray-300 text-center text-lg font-medium text-gray-700";
  const entryCellClass =
    "px-4 py-2 border-b border-gray-300 text-center text-base grid place-items-center";

  // Edit Question Logic Start

  const [categoryName, setCategoryName] = useState("");
  const [status, setStatus] = useState(0);

  useEffect(() => {
    setCategoryName(selectedCategory?.name || "");
    setStatus(selectedCategory?.status);
  }, [selectedCategory]);

  const handleCancel = () => setSelectedCategory(null);

  const handleSave = () => {
    updateCategory(
      {
        id: selectedCategory?.id,
        name: categoryName,
        status: status,
      },
      () => {
        setSelectedCategory(null);
        fetchCategories();
      }
    );
  };
  if (fetchingCategories) {
    return <div className="py-20 text-center">Loading..</div>;
  }
  if (saving) {
    return <div className="py-20 text-center">Saving..</div>;
  }

  return (
    <section className="space-y-20">
      <div className="flex justify-end w-[89%]">
        <button onClick={handleAddCategory} className="border-2 p-2 rounded-lg">
          <img className="size-10" src={plusIcon} alt="Add New Quiz" />
        </button>
      </div>
      <section>
        <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-md">
          <h1 className="text-4xl font-bold text-center mb-6">All Categories</h1>
          <div className="overflow-x-auto">
            <section className="min-w-full bg-white border border-gray-300 text-[1.4em] tablet:text-[1.6em]">
              <div className="grid grid-cols-4 gap-0">
                {headers.map((header) => (
                  <div key={header} className={`${headerClass} bg-gray-100`}>
                    {header}
                  </div>
                ))}
              </div>
              <div className="">
                {categories.map((category) => (
                  <div key={category?.id} className="grid grid-cols-4 gap-0">
                    <div className={entryCellClass}>{category?.id}</div>
                    <div className={entryCellClass}>{category?.name}</div>
                    <div
                      className={`${entryCellClass} ${
                        category?.status ===
                        DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_ACTIVE
                          ? "text-[#31d496] font-[500]"
                          : category?.status ===
                            DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_INACTIVE
                          ? "text-[#da7878] font-[300]"
                          : ""
                      }`}
                    >
                      {DAILY_QUIZ_CATEGORY_STATUS_CONSTANTS.find(
                        (status) => category?.status === status.value
                      )?.text || "UNKNOWN"}
                    </div>
                    <div className={entryCellClass}>
                      <button
                        disabled={selectedCategory}
                        onClick={() => setSelectedCategory(category)}
                        title="Edit"
                      >
                        <img
                          className="size-6"
                          src={selectedCategory ? disabledEditIcon : editIcon}
                          alt="Edit"
                        />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      </section>
      {selectedCategory ? (
        <section className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-md space-y-10">
          <div className="p-[1.6em] border border-gray-300 rounded-md">
            <div>
              <label className="block text-[2em] font-medium text-gray-700">
                Question
              </label>
              <input
                type="text"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                className="mt-[0.4em] block w-full px-[1.2em] py-[0.8em] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500 sm:text-[2em]  text-[1.6em]"
                required
              />
            </div>
            <div className="mt-4 items-center gap-4 flex flex-row-reverse justify-end">
              <label className="block text-[2em] font-medium text-gray-700">
                Enable
              </label>
              <div className="">
                <input
                  type="checkbox"
                  id="vehicle3"
                  name="vehicle3"
                  checked={
                    status === DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_ACTIVE
                  }
                  onChange={() =>
                    setStatus((status) =>
                      status === DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_ACTIVE
                        ? DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_INACTIVE
                        : DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_ACTIVE
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="text-[1.6em] mx-14 flex justify-between">
            <button
              onClick={handleCancel}
              disabled={updating}
              className="px-6 py-3 bg-gray-600 text-white rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              disabled={updating}
              className="px-6 py-3 bg-indigo-600 text-white rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              {false ? "Saving" : "Save Changes"}
            </button>
          </div>
        </section>
      ) : (
        <></>
      )}
    </section>
  );
};

export default Categories;
