import { useContext } from "react";
import { appContext } from "../../context/context";
import useHandleError from "../useHandleError";
import axios from "axios";
import {
  BASE_URL,
  ENDPOINT_GET_LIVE_QUIZ_QUESTIONS,
  ENDPOINT_GET_LIVE_QUIZ_QUESTIONS_QUIZ_ID_IDENTIFIER,
} from "../../constants/APIConstant";

const useGetQuestions = () => {
  const handleError = useHandleError();
  const { token } = useContext(appContext);
  const getQuestions = (id, callback) => {
    axios
      .request({
        method: "GET",
        url:
          BASE_URL +
          ENDPOINT_GET_LIVE_QUIZ_QUESTIONS.replace(
            ENDPOINT_GET_LIVE_QUIZ_QUESTIONS_QUIZ_ID_IDENTIFIER,
            id
          ),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        callback(response?.data);
      })
      .catch(handleError);
  };
  return getQuestions;
};

export default useGetQuestions;
