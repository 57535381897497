import { useContext, useState } from "react";
import useHandleError from "../useHandleError";
import { appContext } from "../../context/context";
import {
  BASE_URL,
  ENDPOINT_STATIC_QUIZ_QUESTIONS,
} from "../../constants/APIConstant";
import axios from "axios";

const useGetStaticQuizQuestions = () => {
  const handleError = useHandleError();
  const { token } = useContext(appContext);
  const [fetchingQuestions, setFetchingQuestions] = useState(false);
  const getStaticQuizQuestions = (filter, page, size, callback) => {
    setFetchingQuestions(true);
    axios
      .request({
        method: "POST",
        url: BASE_URL + ENDPOINT_STATIC_QUIZ_QUESTIONS,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          questionFilter: filter?.questionFilter || null,
          categoryId: Number(filter?.categoryId) || null,
          difficultyLevel: Number(filter?.difficultyLevel) || null,
          status: Number(filter?.status) || null,
        },
        params: {
          size: size,
          page: page,
        },
      })
      .then((response) => {
        callback(response?.data);
      })
      .catch(handleError)
      .finally(() => setFetchingQuestions(false));
  };
  return { getStaticQuizQuestions, fetchingQuestions };
};

export default useGetStaticQuizQuestions;
