import { useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  BASE_URL,
  ENDPOINT_UPDATE_LIVE_QUIZ_QUESTION,
} from "../../constants/APIConstant";
import { appContext } from "../../context/context";

const useUpdateLiveQuizQuestion = () => {
  const [loading, setLoading] = useState(false);
  const { token } = useContext(appContext);

  const updateQuestion = async (questionData, callback) => {
    const id = toast.loading("Updating...");

    setLoading(true);
    // Replace the URL with your API endpoint
    axios
      .request({
        method: "PUT",
        url: `${BASE_URL}${ENDPOINT_UPDATE_LIVE_QUIZ_QUESTION}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(questionData),
      })
      .then(() => {
        callback();
        toast.update(id, {
          render: "Update successful!",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
      })
      .catch((err) => {
        toast.update(id, {
          render: "Update failed!",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      })
      .finally(() => setLoading(false));
  };

  return { updateQuestion, loading };
};

export default useUpdateLiveQuizQuestion;
