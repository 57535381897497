import React, { useContext } from "react";
import { Link, Outlet } from "react-router-dom";
import { appContext } from "../context/context";
import Logo from "../assets/svgs/logo.svg";

const HomePage = ({ sideBarComponents }) => {
  const { logout } = useContext(appContext);
  return (
    <div className="flex flex-col h-screen overflow-y-auto">
      {/* Primary Navbar */}
      <header className="bg-primary border-b-[1px] border-b-[#7766bd]  text-white py-[1.6em] px-[2.4em] flex justify-between items-center">
        <div className="flex gap-x-4 items-center">
          <img src={Logo} className="size-16" alt="" />
          <div className="text-[18px] font-[500] text-center">
            Quiz Wiz Admin
          </div>
        </div>

        <button
          onClick={logout}
          className="text-[1.3em] px-6 py-2  border-[1px] border-[#fff] rounded-full text-center flex justify-center "
        >
          LOGOUT
        </button>
      </header>

      {/* Main Content Wrapper */}
      <div className="flex flex-col tablet:flex-row flex-1">
        {/* Secondary Sidebar Navbar */}
        <nav className="bg-[#d9d1fce6] w-full tablet:w-[28em] p-[1.6em] ">
          <ul className="space-y-[2em]">
            {sideBarComponents &&
              Array.isArray(sideBarComponents) &&
              sideBarComponents.map((sideBarComponent) => (
                <li key={sideBarComponent.text}>
                  <Link
                    to={sideBarComponent.to}
                    className="block text-center text-[1.7em] px-[1.6em] py-[0.8em] text-[#303c4e] font-semibold hover:bg-gray-300 rounded border-2 border-slate-300 box-shadow"
                  >
                    {sideBarComponent.text}
                  </Link>
                </li>
              ))}
          </ul>
        </nav>

        {/* Main Content Area */}
        <main className="flex-1 p-[2.4em]">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default HomePage;
