import React, { useEffect, useState } from "react";
import { appContext as AppContext } from "./context";
import {
  getToken as getTokenFromSessionStorage,
  saveToken as saveTokenInSessionStorege,
} from "../functions/sessionStorageFunction";
import useRefreshToken from "../hooks/auth/useRefreshToken";
import { scheduleTokenRefresh } from "../functions/tokenFunctions";
import { BUFFER_TO_REFRESH_TOKEN_IN_SECONDS } from "../constants/EnvConstants";
import Popup from "reactjs-popup";

const AppState = (props) => {
  const refreshTokenHookFunction = useRefreshToken();
  const reloadWindow = () => window.location.reload();

  // TOKEN
  const [tokenObj, setTokenObj] = useState(getTokenFromSessionStorage());
  const saveToken = (tokenObj) => {
    setTokenObj(tokenObj);
    saveTokenInSessionStorege(tokenObj);
  };

  const logout = () => {
    sessionStorage.clear();
    localStorage.removeItem("autoLogin");
    reloadWindow();
  };

  // TOKEN REFRESH FUNCTIONS <-- START -->
  const refreshTokenCallback = (data) => {
    const tokenObj = data?.tokenObj;
    saveToken(tokenObj);
  };

  useEffect(() => {
    const refreshToken = () => {
      //console.log("Refreshing Token");
      refreshTokenHookFunction(tokenObj.token, refreshTokenCallback);
    };

    if (tokenObj && tokenObj?.tokenSubscriptionVerified) {
      scheduleTokenRefresh(
        tokenObj.expiresAt,
        refreshToken,
        BUFFER_TO_REFRESH_TOKEN_IN_SECONDS
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenObj]);

  // TOKEN REFRESH FUNCTIONS <-- END -->

  // POPUP <<-- START -->>

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupChildren, setPopupChildren] = useState(<></>);
  const showPopup = (popupChildren) => {
    setPopupChildren(popupChildren);
    setIsPopupOpen(true);
  };
  const closePopup = () => {
    setPopupChildren(<></>);
    setIsPopupOpen(false);
  };
  // POPUP <<-- END -->>

  return (
    <AppContext.Provider
      value={{
        token: tokenObj?.token,
        saveToken,
        isLoggedIn: !!tokenObj,
        showPopup,
        closePopup,
        logout,
      }}
    >
      <Popup open={isPopupOpen} closeOnDocumentClick onClose={closePopup}>
        <div className="h-screen  bg-[#2f2a429f] w-[100vw] flex justify-center items-center">
          {popupChildren}
        </div>
      </Popup>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
