import React, { useState, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  LIVE_QUIZ_STATUS_CONSTANTS,
  LIVE_QUIZ_STATUS_CONSTANT_ENDED,
  LIVE_QUIZ_STATUS_CONSTANT_SCHEDULED,
} from "../../../constants/LiveQuizStatusConstants";
import useGetLiveQuizzes from "../../../hooks/liveQuiz/useGetLiveQuizzes";
import useCancelLiveQuiz from "../../../hooks/liveQuiz/useCancelLiveQuiz";
import editIcon from "../../../assets/svgs/pencil-writing-blue-circle-20625.svg";
import cancelIcon from "../../../assets/svgs/cancel-close-10374.svg";
import { Link } from "react-router-dom";
import {
  EditLiveQuizzesRoute,
  EditLiveQuizzesRouteTitleIdentifier,
  LiveQuizRouteIdIdentifier,
  LiveQuizStatsRoute,
  NewLiveQuizRoute,
} from "../../../constants/RouteConstants";
import plusIcon from "../../../assets/svgs/green-plus-11975.svg";

const AllLiveQuizzes = () => {
  const [quizzes, setQuizzes] = useState([]);
  const pageRef = useRef(0);
  const page = pageRef.current;
  const setPage = (newVal) => (pageRef.current = newVal);
  const [hasMore, setHasMore] = useState(true);
  const quizzesPerPage = 5; // Number of quizzes to load per request
  const getQuizzes = useGetLiveQuizzes();
  const cancelQuiz = useCancelLiveQuiz();

  const fetchQuizzes = () => {
    const fetchQuizzesCallBack = (newQuizzes) => {
      setQuizzes((prevQuizzes) => [...prevQuizzes, ...newQuizzes]);
      setPage(page + 1);
      if (newQuizzes.length < quizzesPerPage) {
        setHasMore(false); // Stop fetching if fewer quizzes are returned
      }
    };

    getQuizzes(page, quizzesPerPage, fetchQuizzesCallBack);
  };

  useEffect(() => {
    fetchQuizzes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Ensure this runs once on mount

  const headers = [
    "Name",
    "Description",
    "Status",
    "Prize",
    "Schedule Date & Time",
    "Start Date & Time",
    "End Date & Time",
    "Options",
  ];

  const headerClass =
    "px-4 py-2 border-b-2 border-gray-300 text-center text-lg font-medium text-gray-700";
  const entryCellClass =
    "px-4 py-2 border-b border-gray-300 text-center text-base";

  const handleCancel = (quiz) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to cancel the quiz: ${quiz?.name}?`)) {
      const callback = () => {
        // Refetch quizzes after cancelling
        const fetchQuizzesCallBack = (newQuizzes) => {
          setQuizzes(newQuizzes);
          setPage(1); // Update page state correctly
          setHasMore(newQuizzes.length >= quizzesPerPage);
        };
        getQuizzes(0, quizzesPerPage, fetchQuizzesCallBack);
      };
      cancelQuiz(quiz?.id, callback);
    }
  };

  return (
    <section className="space-y-10">
      <div className="flex justify-end w-[89%]">
        <Link to={NewLiveQuizRoute} className="border-2 p-2 rounded-lg">
          <img className="size-10" src={plusIcon} alt="Add New Quiz" />
        </Link>
      </div>
      <section>
        <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-md">
          <h1 className="text-4xl font-bold text-center mb-6">Live Quizzes</h1>
          <div className="overflow-x-auto max-h-[70vh] overflow-y-auto">
            <section className="min-w-full bg-white border border-gray-300 text-[1.4em] tablet:text-[1.6em]">
              <div className="grid grid-cols-8 gap-0">
                {headers.map((header) => (
                  <div key={header} className={`${headerClass} bg-gray-100`}>
                    {header}
                  </div>
                ))}
              </div>
              <InfiniteScroll
                dataLength={quizzes.length}
                next={fetchQuizzes}
                hasMore={hasMore}
                loader={<h4 className="text-center my-4">Loading...</h4>}
                endMessage={<p className="text-center mt-4">No more quizzes</p>}
              >
                {quizzes.map((quiz) => (
                  <div key={quiz?.id} className="grid grid-cols-8 gap-0">
                    <div className={entryCellClass}>{quiz?.name}</div>
                    <div className={entryCellClass}>{quiz?.description}</div>
                    <div className={entryCellClass}>
                      {LIVE_QUIZ_STATUS_CONSTANTS.find(
                        (status) => quiz?.status === status.value
                      )?.text || "UNKNOWN"}
                    </div>
                    <div className={entryCellClass}>{quiz?.prizeText}</div>
                    <div className={entryCellClass}>
                      {quiz?.scheduleDate}
                      <br /> {quiz?.scheduledTime}
                    </div>
                    <div className={entryCellClass}>
                      {quiz?.startDate}
                      <br /> {quiz?.startTime}
                    </div>
                    <div className={entryCellClass}>
                      {quiz?.endedDate}
                      <br /> {quiz?.endedTime}
                    </div>
                    <div className={entryCellClass}>
                      {quiz?.status === LIVE_QUIZ_STATUS_CONSTANT_SCHEDULED ? (
                        <div className="grid grid-cols-2 gap-4 h-full">
                          <div className="h-full flex items-center">
                            <Link
                              to={EditLiveQuizzesRoute.replace(
                                EditLiveQuizzesRouteTitleIdentifier,
                                quiz?.id
                              )}
                              state={{ quiz }}
                              title="Edit"
                            >
                              <img
                                className="size-6"
                                src={editIcon}
                                alt="Edit"
                              />
                            </Link>
                          </div>
                          <div className="h-full flex items-center">
                            <button
                              title="Cancel"
                              onClick={() => handleCancel(quiz)}
                            >
                              <img
                                className="size-6"
                                src={cancelIcon}
                                alt="Cancel"
                              />
                            </button>
                          </div>
                        </div>
                      ) : quiz?.status === LIVE_QUIZ_STATUS_CONSTANT_ENDED ? (
                        <Link
                          to={LiveQuizStatsRoute.replace(
                            LiveQuizRouteIdIdentifier,
                            quiz?.id
                          )}
                          className="underline text-green-600 hover:text-green-800 transition duration-500 ease-in-out"
                        >
                          Stats
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
              {hasMore ? (
                <div className="flex justify-center py-8">
                  <button
                    onClick={fetchQuizzes}
                    className="px-5 py-2 rounded-lg bg-gray-400 hover:border-black border-2 hover:bg-transparent "
                  >
                    Fetch More
                  </button>
                </div>
              ) : (
                <></>
              )}
            </section>
          </div>
        </div>
      </section>
    </section>
  );
};

export default AllLiveQuizzes;
