export const BaseRoute = "/";
export const DashboardRoute = "/";
export const LiveQuizRoute = DashboardRoute + "live-quiz";
export const NewLiveQuizRoute = LiveQuizRoute + "/new";
export const EditLiveQuizzesRoute = LiveQuizRoute + "/edit/:id";
export const EditLiveQuizzesRouteTitleIdentifier = ":id";
export const SeeLiveQuizQuestionsRoute = LiveQuizRoute + "/questions/:id"; // this is quiz Id
export const EditLiveQuizQuestionRoute = LiveQuizRoute + "/question/:id"; // this is question Id
export const EditLiveQuizQuestionRouteIdIdentifier = ":id";
export const LiveQuizStatsRoute = LiveQuizRoute + "/stats/:id";
export const LiveQuizRouteIdIdentifier = ":id";
// export const DailyQuizRoute = "daily-quiz";
export const DailyQuizRoute = DashboardRoute + "daily-quiz";
export const LoginPageRoute = "/login";
export const NoMatchRoute = "*";
export const CategoriesRoute = DailyQuizRoute + "/categories";
export const QuestionsRoute = DailyQuizRoute + "/questions";
export const UplaodQuestionsFileRoute = QuestionsRoute + "/upload-csv";
export const QuestionsStatsRoute = QuestionsRoute + "/stats";
