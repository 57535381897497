import React from "react";
import { Link } from "react-router-dom";

const Dashboard = ({ sideBarComponents }) => {
  return (
    <div className="flex flex-col p-6 space-y-6">
      <h1 className="text-3xl font-bold mb-6">Dashboard</h1>
      <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {sideBarComponents &&
          Array.isArray(sideBarComponents) &&
          sideBarComponents.map((sideBarComponent) => (
            <Link
              key={sideBarComponent.text}
              to={sideBarComponent.to}
              className={` text-white p-6 rounded-lg shadow-md flex items-center justify-center transition ${
                sideBarComponent?.className || ""
              }`}
            >
              <div className="text-center">
                <h2 className="text-2xl font-bold">{sideBarComponent.text}</h2>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default Dashboard;
