import React, { useState, useEffect } from "react";
import editIcon from "../../../assets/svgs/pencil-writing-blue-circle-20625.svg";
import disabledEditIcon from "../../../assets/svgs/disabled-pencil-writing-blue-circle-20625.svg";
import useGetQuestions from "../../../hooks/liveQuiz/useGetQuestions"; // Assuming you have a hook to fetch questions
import { useLocation } from "react-router-dom";
import useUpdateLiveQuizQuestion from "../../../hooks/liveQuiz/useUpdateLiveQuizQuestion";

const AllQuestions = () => {
  const location = useLocation();
  const quiz = location?.state?.quiz;
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const getQuestions = useGetQuestions();
  const { updateQuestion, loading } = useUpdateLiveQuizQuestion();

  const fetchQuestions = () =>
    getQuestions(quiz?.id, (fetchedQuestions) => {
      setQuestions(fetchedQuestions);
    });
  useEffect(() => {
    // Fetch questions when component mounts
    fetchQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headers = [
    "Question",
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 4",
    "Operations",
  ];

  const headerClass =
    "px-4 py-2 border-b-2 border-gray-300 text-center text-lg font-medium text-gray-700";
  const entryCellClass =
    "px-4 py-2 border-b border-gray-300 text-center text-base grid place-items-center";

  // Edit Question Logic Start

  const [question, setQuestion] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");
  const [correctOptionNumber, setCorrectOptionNumber] = useState(0);

  useEffect(() => {
    setQuestion(selectedQuestion?.question || "");
    setOption1(selectedQuestion?.option1 || "");
    setOption2(selectedQuestion?.option2 || "");
    setOption3(selectedQuestion?.option3 || "");
    setOption4(selectedQuestion?.option4 || "");
    setCorrectOptionNumber(selectedQuestion?.correctOptionNo || 1);
  }, [selectedQuestion]);

  const handleCancel = () => setSelectedQuestion(null);

  const handleSave = () => {
    updateQuestion(
      {
        id: selectedQuestion?.id,
        question,
        option1,
        option2,
        option3,
        option4,
        correctOptionNumber,
      },
      () => {
        setSelectedQuestion(null);
        fetchQuestions();
      }
    );
  };

  const selectedQuestionOptions = [
    { value: option1, setter: setOption1 },
    { value: option2, setter: setOption2 },
    { value: option3, setter: setOption3 },
    { value: option4, setter: setOption4 },
  ];

  return (
    <section className="space-y-20">
      <section>
        <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-md">
          <h1 className="text-4xl font-bold text-center mb-6">All Questions</h1>
          <div className="overflow-x-auto">
            <section className="min-w-full bg-white border border-gray-300 text-[1.4em] tablet:text-[1.6em]">
              <div className="grid grid-cols-6 gap-0">
                {headers.map((header) => (
                  <div key={header} className={`${headerClass} bg-gray-100`}>
                    {header}
                  </div>
                ))}
              </div>
              <div className="">
                {questions.map((question) => (
                  <div key={question?.id} className="grid grid-cols-6 gap-0">
                    <div className={entryCellClass}>{question?.question}</div>
                    {[1, 2, 3, 4].map((index) => (
                      <div
                        key={index}
                        className={`${entryCellClass} ${
                          question?.correctOptionNo === index
                            ? "text-[#31d496] font-[500]"
                            : ""
                        }`}
                      >
                        {question?.[`option${index}`]}
                      </div>
                    ))}
                    <div className={entryCellClass}>
                      <button
                        disabled={selectedQuestion}
                        onClick={() => setSelectedQuestion(question)}
                        title="Edit"
                      >
                        <img
                          className="size-6"
                          src={selectedQuestion ? disabledEditIcon : editIcon}
                          alt="Edit"
                        />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      </section>
      {selectedQuestion ? (
        <section className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-md space-y-10">
          <div className="p-[1.6em] border border-gray-300 rounded-md">
            <div>
              <label className="block text-[2em] font-medium text-gray-700">
                Question
              </label>
              <input
                type="text"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                className="mt-[0.4em] block w-full px-[1.2em] py-[0.8em] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500 sm:text-[2em]  text-[1.6em]"
                required
              />
            </div>
            <div className="mt-4">
              <label className="block text-[2em] font-medium text-gray-700">
                Options
              </label>
              <div className="grid grid-cols-2 gap-x-4 gap-y-10">
                {selectedQuestionOptions.map((option, optIndex) => (
                  <div
                    key={optIndex}
                    className="mt-[0.4em] flex items-center gap-10 p-4 border-[1px] rounded-lg"
                  >
                    <input
                      type="radio"
                      checked={correctOptionNumber === optIndex + 1}
                      onChange={() => setCorrectOptionNumber(optIndex + 1, 10)}
                      className="cursor-pointer size-[2em]"
                    />
                    <input
                      type="text"
                      value={option.value}
                      onChange={(e) => option.setter(e.target.value)}
                      className="block w-full px-[1.2em] py-[0.8em] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500 sm:text-[2em] mb-2  text-[1.6em]"
                      required
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="text-[1.6em] mx-14 flex justify-between">
            <button
              onClick={handleCancel}
              disabled={loading}
              className="px-6 py-3 bg-gray-600 text-white rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              disabled={loading}
              className="px-6 py-3 bg-indigo-600 text-white rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              {false ? "Saving" : "Save Changes"}
            </button>
          </div>
        </section>
      ) : (
        <></>
      )}
    </section>
  );
};

export default AllQuestions;
