import "./App.css";
import {
  BaseRoute,
  CategoriesRoute,
  DailyQuizRoute,
  DashboardRoute,
  EditLiveQuizzesRoute,
  LiveQuizRoute,
  LiveQuizStatsRoute,
  LoginPageRoute,
  NewLiveQuizRoute,
  NoMatchRoute,
  QuestionsRoute,
  QuestionsStatsRoute,
  SeeLiveQuizQuestionsRoute,
  UplaodQuestionsFileRoute,
} from "./constants/RouteConstants";
import AuthRoute from "./components/AuthRoute";
import LoginPage from "./pages/LoginPage";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Dashboard from "./components/Home/Dashboard";
import DailyQuizPage from "./pages/DailyQuizPage";
import NewLiveQuizForm from "./components/Home/liveQuiz/NewLiveQuizForm";
import AllLiveQuizes from "./components/Home/liveQuiz/AllLiveQuizes";
import EditLiveQuiz from "./components/Home/liveQuiz/EditLiveQuiz";
import LiveQuizQuestion from "./components/Home/liveQuiz/LiveQuizQuestion";
import Categories from "./components/Home/dailyQuiz/Categories";
import Questions from "./components/Home/dailyQuiz/Questions";
import UploadQuestion from "./components/Home/dailyQuiz/UploadQuestion";
import questionsImage from "./assets/images/question-mark.png";
import statsImage from "./assets/images/stats.png";
import categoriesImage from "./assets/images/classification.png";
import QuestionStats from "./components/Home/dailyQuiz/QuestionStats";
import LiveQuizStats from "./components/Home/liveQuiz/LiveQuizStats";

function App() {
  const sideBarComponents = [
    {
      text: "Live Quiz",
      to: LiveQuizRoute,
      className: "bg-green-600 hover:bg-green-700",
    },
    {
      text: "Daily Quiz",
      to: DailyQuizRoute,
      className: "bg-blue-600 hover:bg-blue-700 ",
    },
  ];
  const liveQuizSubComponents = [
    {
      text: "New Quiz",
      to: NewLiveQuizRoute,
      element: <NewLiveQuizForm />,
    },
    {
      text: "All Quizzes",
      to: LiveQuizRoute,
      element: <AllLiveQuizes />,
    },
  ];
  const dailyQuizSubComponents = [
    {
      text: "Categories",
      to: CategoriesRoute,
      element: <Categories />,
      image: categoriesImage,
    },
    {
      text: "Questions",
      to: QuestionsRoute,
      element: <Questions />,
      image: questionsImage,
    },
    {
      text: "Questions Stats",
      to: QuestionsStatsRoute,
      element: <QuestionStats />,
      image: statsImage,
    },
  ];
  const pageRoutes = [
    {
      path: LoginPageRoute,
      element: <LoginPage />,
      isProtected: false,
    },
  ];

  const dashboardOutletRoutes = [
    {
      path: DashboardRoute,
      element: <Dashboard sideBarComponents={sideBarComponents} />,
    },
    {
      path: DailyQuizRoute,
      element: <DailyQuizPage sideBarComponents={dailyQuizSubComponents} />,
    },
    {
      path: EditLiveQuizzesRoute,
      element: <EditLiveQuiz />,
    },
    {
      path: SeeLiveQuizQuestionsRoute,
      element: <LiveQuizQuestion />,
    },
    {
      path: UplaodQuestionsFileRoute,
      element: <UploadQuestion />,
    },
    {
      path: LiveQuizStatsRoute,
      element: <LiveQuizStats />,
    },
    ...liveQuizSubComponents.map((item) => ({
      path: item.to,
      element: item.element,
      isProtected: true,
    })),
    ...dailyQuizSubComponents.map((item) => ({
      path: item.to,
      element: item.element,
      isProtected: true,
    })),
  ];
  return (
    <section className="h-lvh tablet:h-screen overflow-y-auto">
      <Routes>
        <Route element={<AuthRoute />}>
          <Route
            path={BaseRoute}
            element={<Home sideBarComponents={sideBarComponents} />}
          >
            {dashboardOutletRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
            <Route
              path={NoMatchRoute}
              element={<Navigate to={DashboardRoute} replace />}
            />
          </Route>
        </Route>
        {pageRoutes.map((route) =>
          route.isProtected ? (
            <Route key={route.path} element={<AuthRoute />}>
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            </Route>
          ) : (
            <Route key={route.path} path={route.path} element={route.element} />
          )
        )}
        <Route
          path={NoMatchRoute}
          element={<Navigate to={DashboardRoute} replace />}
        />
      </Routes>
    </section>
  );
}

export default App;
