import { useContext } from "react";
import { appContext } from "../../context/context";
import useHandleError from "../useHandleError";
import axios from "axios";
import {
  BASE_URL,
  ENDPOINT_CANCEL_LIVE_QUIZ,
} from "../../constants/APIConstant";

const useCancelLiveQuiz = () => {
  const handleError = useHandleError();
  const { token } = useContext(appContext);
  const cancelLiveQuiz = (id, callback) => {
    axios
      .request({
        method: "POST",
        url: BASE_URL + ENDPOINT_CANCEL_LIVE_QUIZ,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: id,
        },
      })
      .then(callback)
      .catch(handleError);
  };
  return cancelLiveQuiz;
};

export default useCancelLiveQuiz;
