import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Slug } from "./constants/Slug";
import { BrowserRouter } from "react-router-dom";
import AppState from "./context/AppState";
import DeviceState from "./context/DeviceState";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));
const useStrictMode = process.env.REACT_APP_USE_STRICT_MODE === "true";

const rootChildren = (
  <BrowserRouter basename={Slug}>
    <DeviceState>
      <AppState>
        <App />
        <ToastContainer
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
        />
      </AppState>
    </DeviceState>
  </BrowserRouter>
);

root.render(
  useStrictMode ? (
    <React.StrictMode>{rootChildren}</React.StrictMode>
  ) : (
    rootChildren
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
