import React, { useState, useEffect } from "react";
import useSaveLiveQuiz from "../../../hooks/liveQuiz/useSaveLiveQuiz";
import { useNavigate } from "react-router-dom";
import { LiveQuizRoute } from "../../../constants/RouteConstants";
import { hasCurrentTimePassed } from "../../../functions/timeFunctions";

const initialQuestion = {
  question: "",
  option1: "",
  option2: "",
  option3: "",
  option4: "",
  correctOptionNumber: 1,
};

const QuizForm = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [prizeText, setPrizeText] = useState("");
  const [scheduledTimestamp, setScheduledTimestamp] = useState("");
  const [questions, setQuestions] = useState(
    Array(5).fill({ ...initialQuestion })
  );
  const { saveLiveQuiz, loading } = useSaveLiveQuiz();

  useEffect(() => {
    const savedQuizData = JSON.parse(localStorage.getItem("quizData"));
    if (savedQuizData) {
      setTitle(savedQuizData.title);
      setDescription(savedQuizData.description);
      setPrizeText(savedQuizData.prizeText);
      setQuestions(savedQuizData.liveQuizQuestions);
    }
  }, []);

  const handleAddQuestion = () => {
    setQuestions([...questions, { ...initialQuestion }]);
  };

  const handleRemoveQuestion = () => {
    if (questions.length > 1) {
      setQuestions(questions.slice(0, -1));
    }
  };

  const handleChangeQuestion = (index, field, value) => {
    const updatedQuestions = questions.map((question, i) =>
      i === index ? { ...question, [field]: value } : question
    );
    setQuestions(updatedQuestions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (hasCurrentTimePassed(scheduledTimestamp)) {
      alert("Invalid Scheduled Date Time");
      return;
    }
    const quizData = {
      title,
      description,
      prizeText,
      scheduledTimestamp,
      liveQuizQuestions: questions,
    };
    console.log("Quiz Data:", quizData);
    localStorage.setItem("quizData", JSON.stringify(quizData));
    saveLiveQuiz(quizData, () => {
      navigate(LiveQuizRoute);
    });
  };

  const handleScheduledTimestampChange = (e) => {
    const value = e.target.value;
    const now = new Date().toISOString().slice(0, 16);
    if (value >= now) {
      setScheduledTimestamp(`${value}:00`);
    } else {
      alert("Please select a future date and time.");
    }
  };

  return (
    <section>
      <div className="max-w-[89em] mx-auto p-[2.4em] bg-white rounded-[2em] shadow-md">
        <h1 className="text-[2.8em] font-bold mb-[2.4em]">Create Quiz</h1>
        <form onSubmit={handleSubmit} className="space-y-[2.4em]">
          <div>
            <label className="block text-[2em] font-medium text-gray-700">
              Title
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="mt-[0.4em]  text-[1.6em] block w-full px-[1.2em] py-[0.8em] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500 sm:text-[2em]"
              required
            />
          </div>
          <div>
            <label className="block text-[2em] font-medium text-gray-700">
              Description
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-[0.4em] text-[1.6em] block w-full px-[1.2em] py-[0.8em] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500 sm:text-[2em]"
              rows="4"
              required
            />
          </div>
          <div>
            <label className="block text-[2em] font-medium text-gray-700">
              Prize Text
            </label>
            <input
              type="text"
              value={prizeText}
              onChange={(e) => setPrizeText(e.target.value)}
              className="mt-[0.4em] text-[1.6em] block w-full px-[1.2em] py-[0.8em] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500 sm:text-[2em]"
              required
            />
          </div>
          <div>
            <label className="block text-[2em] font-medium text-gray-700">
              Scheduled Date Time
            </label>
            <input
              type="datetime-local"
              value={scheduledTimestamp}
              onChange={handleScheduledTimestampChange}
              min={new Date()
                .toISOString()
                .slice(0, new Date().toISOString().lastIndexOf(":"))}
              className="mt-[0.4em]  text-[1.6em] block w-full px-[1.2em] py-[0.8em] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500 sm:text-[2em]"
              required
            />
          </div>
          <div className="text-[1.6em] flex justify-between items-center border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500 sm:text-[2em] px-[1.2em] py-[0.8em] my-[2em]">
            <div>No of Questions: </div>
            <div className="w-40 flex justify-between items-center">
              <button
                type="button"
                onClick={handleRemoveQuestion}
                className="bg-red-500 hover:bg-red-600 text-white px-[1em] py-[0.5em] rounded"
              >
                -
              </button>
              <div className="">{questions.length}</div>
              <button
                type="button"
                onClick={handleAddQuestion}
                className="bg-blue-600 hover:bg-blue-700 text-white px-[1em] py-[0.5em] rounded"
              >
                +
              </button>
            </div>
          </div>
          <div className="space-y-4">
            {questions.map((question, index) => (
              <div
                key={index}
                className="p-[1.6em] border border-gray-300 rounded-md"
              >
                <h2 className="text-xl font-semibold mb-2">
                  Question {index + 1}
                </h2>
                <div>
                  <label className="block text-[2em] font-medium text-gray-700">
                    Question
                  </label>
                  <input
                    type="text"
                    value={question.question}
                    onChange={(e) =>
                      handleChangeQuestion(index, "question", e.target.value)
                    }
                    className="mt-[0.4em] block w-full px-[1.2em] py-[0.8em] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500 sm:text-[2em]  text-[1.6em]"
                    required
                  />
                </div>
                <div className="mt-4">
                  <label className="block text-[2em] font-medium text-gray-700">
                    Options
                  </label>
                  <div className="grid grid-cols-2 gap-x-4 gap-y-10">
                    {["option1", "option2", "option3", "option4"].map(
                      (option, optIndex) => (
                        <div
                          key={optIndex}
                          className="mt-[0.4em] flex items-center gap-10 p-4 border-[1px] rounded-lg"
                        >
                          <input
                            type="radio"
                            checked={
                              optIndex + 1 === question.correctOptionNumber
                            }
                            onChange={() =>
                              handleChangeQuestion(
                                index,
                                "correctOptionNumber",
                                parseInt(optIndex + 1, 10)
                              )
                            }
                            className="cursor-pointer size-[2em]"
                          />
                          <input
                            type="text"
                            value={question[option]}
                            onChange={(e) =>
                              handleChangeQuestion(
                                index,
                                option,
                                e.target.value
                              )
                            }
                            className="block w-full px-[1.2em] py-[0.8em] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500 sm:text-[2em] mb-2  text-[1.6em]"
                            required
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button
            disabled={loading}
            type="submit"
            className="bg-green-600 hover:bg-green-700 text-white px-[1.6em] py-[0.8em] rounded"
          >
            Submit
          </button>
        </form>
      </div>
    </section>
  );
};

export default QuizForm;
