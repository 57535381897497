import { useContext, useState } from "react";
import useHandleError from "../useHandleError";
import { appContext } from "../../context/context";
import {
  BASE_URL,
  ENDPOINT_COUNT_STATIC_QUIZ_QUESTIONS,
} from "../../constants/APIConstant";
import axios from "axios";

const useCountStaticQuizQuestions = () => {
  const handleError = useHandleError();
  const { token } = useContext(appContext);
  const [fetchingCount, setFetchingCount] = useState(false);

  const countStaticQuizQuestions = (filter, callback) => {
    setFetchingCount(true);
    axios
      .request({
        method: "POST",
        url: BASE_URL + ENDPOINT_COUNT_STATIC_QUIZ_QUESTIONS,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: filter,
      })
      .then((response) => {
        callback(response?.data);
      })
      .catch(handleError)
      .finally(() => setFetchingCount(false));
  };

  return { countStaticQuizQuestions, fetchingCount };
};

export default useCountStaticQuizQuestions;
