export const BASE_URL = process.env.REACT_APP_API_URL;

// AUTH ENDPOINTS
export const ENDPOINT_LOGIN = process.env.REACT_APP_ENDPOINT_LOGIN;
export const ENDPOINT_REFRESH_TOKEN =
  process.env.REACT_APP_ENDPOINT_REFRESH_TOKEN;

// LIVE QUIZ ENDPOINTS
export const ENDPOINT_SAVE_LIVE_QUIZ =
  process.env.REACT_APP_ENDPOINT_SAVE_LIVE_QUIZ;
export const ENDPOINT_UPDATE_LIVE_QUIZ =
  process.env.REACT_APP_ENDPOINT_UPDATE_LIVE_QUIZ;
export const ENDPOINT_GET_ALL_LIVE_QUIZ =
  process.env.REACT_APP_ENDPOINT_GET_ALL_LIVE_QUIZ;
export const ENDPOINT_GET_LIVE_QUIZ_BY_ID =
  process.env.REACT_APP_ENDPOINT_GET_LIVE_QUIZ_BY_ID;
export const ENDPOINT_GET_LIVE_QUIZ_STATS =
  process.env.REACT_APP_ENDPOINT_GET_LIVE_QUIZ_STATS;
export const ENDPOINT_GET_LIVE_QUIZ_BY_ID_ID_IDENTIFIER =
  process.env.REACT_APP_ENDPOINT_GET_LIVE_QUIZ_BY_ID_ID_IDENTIFIER;
export const ENDPOINT_CANCEL_LIVE_QUIZ =
  process.env.REACT_APP_ENDPOINT_CANCEL_LIVE_QUIZ;
export const ENDPOINT_GET_LIVE_QUIZ_QUESTIONS =
  process.env.REACT_APP_ENDPOINT_GET_LIVE_QUIZ_QUESTIONS;
export const ENDPOINT_GET_LIVE_QUIZ_QUESTIONS_QUIZ_ID_IDENTIFIER =
  process.env.REACT_APP_ENDPOINT_GET_LIVE_QUIZ_QUESTIONS_QUIZ_ID_IDENTIFIER;
export const ENDPOINT_GET_LIVE_QUIZ_QUESTION_BY_ID =
  process.env.REACT_APP_ENDPOINT_GET_LIVE_QUIZ_QUESTION_BY_ID;
export const ENDPOINT_GET_LIVE_QUIZ_QUESTION_BY_ID_ID_IDENTIFIER =
  process.env.REACT_APP_ENDPOINT_GET_LIVE_QUIZ_QUESTION_BY_ID_ID_IDENTIFIER;
export const ENDPOINT_UPDATE_LIVE_QUIZ_QUESTION =
  process.env.REACT_APP_ENDPOINT_UPDATE_LIVE_QUIZ_QUESTION;

// STATIC QUIZ ENDPOINTS
// QUESTIONS
export const ENDPOINT_STATIC_QUIZ_QUESTIONS =
  process.env.REACT_APP_ENDPOINT_STATIC_QUIZ_QUESTIONS;
export const ENDPOINT_COUNT_STATIC_QUIZ_QUESTIONS =
  process.env.REACT_APP_ENDPOINT_COUNT_STATIC_QUIZ_QUESTIONS;
export const ENDPOINT_ADD_STATIC_QUIZ_QUESTION =
  process.env.REACT_APP_ENDPOINT_ADD_STATIC_QUIZ_QUESTION;
export const ENDPOINT_UPDATE_STATIC_QUIZ_QUESTION =
  process.env.REACT_APP_ENDPOINT_UPDATE_STATIC_QUIZ_QUESTION;
export const ENDPOINT_UPLOAD_CSV_FILE =
  process.env.REACT_APP_ENDPOINT_UPLOAD_CSV_FILE;
export const ENDPOINT_GET_STATIC_QUIZ_QUESTION_STATS =
  process.env.REACT_APP_ENDPOINT_GET_STATIC_QUIZ_QUESTION_STATS;
// CATEGORIES
export const ENDPOINT_GET_ALL_CATEGORIES =
  process.env.REACT_APP_ENDPOINT_GET_ALL_CATEGORIES;
export const ENDPOINT_ADD_CATEGORY =
  process.env.REACT_APP_ENDPOINT_ADD_CATEGORY;
export const ENDPOINT_UPDATE_CATEGORY =
  process.env.REACT_APP_ENDPOINT_UPDATE_CATEGORY;
