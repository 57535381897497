import axios from "axios";
import useHandleError from "../useHandleError";
import {
  BASE_URL,
  ENDPOINT_UPDATE_LIVE_QUIZ,
} from "../../constants/APIConstant";
import { useContext, useState } from "react";
import { appContext } from "../../context/context";
import { toast } from "react-toastify";

const useUpdateLiveQuiz = () => {
  const handleError = useHandleError();
  const [loading, setLoading] = useState(false);
  const { token } = useContext(appContext);
  const updateLiveQuiz = (quizData, callback) => {
    const id = toast.loading("Saving...");
    setLoading(true);
    axios
      .request({
        method: "PUT",
        url: BASE_URL + ENDPOINT_UPDATE_LIVE_QUIZ,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(quizData),
      })
      .then((response) => {
        toast.update(id, {
          render: response?.data?.message || response?.data,
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        callback();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };
  return { updateLiveQuiz, loading };
};

export default useUpdateLiveQuiz;
