import { useContext, useState } from "react";
import useHandleError from "../useHandleError";
import { appContext } from "../../context/context";
import axios from "axios";
import {
  BASE_URL,
  ENDPOINT_GET_STATIC_QUIZ_QUESTION_STATS,
} from "../../constants/APIConstant";

const useGetStaticQuizQuestionStats = () => {
  const handleError = useHandleError();
  const { token } = useContext(appContext);
  const [fetchingStats, setFetchingStats] = useState(false);
  const getStaticQuizQuestionStats = (callback) => {
    setFetchingStats(true);
    axios
      .request({
        method: "GET",
        url: BASE_URL + ENDPOINT_GET_STATIC_QUIZ_QUESTION_STATS,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        callback(response?.data);
      })
      .catch(handleError)
      .finally(() => setFetchingStats(false));
  };
  return { getStaticQuizQuestionStats, fetchingStats };
};

export default useGetStaticQuizQuestionStats;
