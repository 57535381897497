export const DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_ACTIVE = 0;
export const DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_ACTIVE_TEXT = "ENABLED";
export const DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_INACTIVE = 1;
export const DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_INACTIVE_TEXT = "DISABLED";
export const DAILY_QUIZ_CATEGORY_STATUS_CONSTANTS = [
  {
    text: DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_ACTIVE_TEXT,
    value: DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_ACTIVE,
  },
  {
    text: DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_INACTIVE_TEXT,
    value: DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_INACTIVE,
  },
];
