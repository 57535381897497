import React, { useEffect, useState } from "react";
import useGetStaticQuizQuestionStats from "../../../hooks/staticQuiz/useGetStaticQuizQuestionStats";
import useGetAllCategories from "../../../hooks/staticQuiz/useGetAllCategories";
import { DAILY_QUIZ_DIFFICULTY_LEVELS } from "../../../constants/DailyQuizDifficultyLevelConstants";
import {
  DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_ACTIVE,
  DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_INACTIVE,
} from "../../../constants/DailyQuizCategoryStatusConstants";

const QuestionStats = () => {
  const { getStaticQuizQuestionStats, fetchingStats } =
    useGetStaticQuizQuestionStats();
  const { getAllCategories, fetchingCategories } = useGetAllCategories();
  const [categories, setCategories] = useState([]);
  const [stats, setStats] = useState([{}]);
  useEffect(() => {
    getStaticQuizQuestionStats(setStats);
    getAllCategories(setCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const segregatedStats = segregateData(stats);

  const getStatsforCell = (categoryId, difficultyLevel) => {
    const entry = segregatedStats.find(
      (entry) =>
        entry?.categoryId === categoryId &&
        entry?.difficultyLevel === difficultyLevel
    ) || {
      activeCount: 0,
      inactiveCount: 0,
    };
    return {
      activeCount: entry.activeCount,
      inactiveCount: entry.inactiveCount,
    };
  };

  return (
    <div className="container mx-auto p-4 text-center text-[1.6em]">
      <h1 className="text-2xl font-bold mb-4">DAILY QUIZ QUESTION STATS</h1>
      {fetchingStats || fetchingCategories ? (
        <div>Loading...</div>
      ) : (
        <div className="tableFixHead">
          <table className="w-full text-sm text-center text-gray-500">
            <thead className="text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Category
                </th>
                {DAILY_QUIZ_DIFFICULTY_LEVELS.map((difficultyLevel) => (
                  <th
                    key={difficultyLevel.value}
                    scope="col"
                    className="px-6 py-3"
                  >
                    {difficultyLevel.text}
                  </th>
                ))}

                <th scope="col" className="px-6 py-3">
                  Total
                </th>
              </tr>
            </thead>
            <tbody className="">
              {categories
                .sort((a, b) => a.status - b.status)
                .map((category) => {
                  const questionStatsForCategory = {
                    activeCount: 0,
                    inactiveCount: 0,
                    totalCountCount: 0,
                  };
                  const entryCellsData = [];
                  DAILY_QUIZ_DIFFICULTY_LEVELS.forEach((difficultyLevel) => {
                    const cellStats = getStatsforCell(
                      category.id,
                      difficultyLevel.value
                    );
                    entryCellsData.push(cellStats);
                    questionStatsForCategory["activeCount"] =
                      questionStatsForCategory?.activeCount +
                      cellStats.activeCount;
                    questionStatsForCategory["inactiveCount"] =
                      questionStatsForCategory?.inactiveCount +
                      cellStats.inactiveCount;
                  });
                  entryCellsData.push(questionStatsForCategory);
                  return (
                    <tr key={category.id} className="border-y-2">
                      <th
                        scope="col"
                        className={`px-6 py-3 ${
                          category?.status ===
                          DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_ACTIVE
                            ? "text-[#31d496] font-[500]"
                            : category?.status ===
                              DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_INACTIVE
                            ? "text-[#da7878] font-[300]"
                            : ""
                        }`}
                      >
                        {category.name}
                      </th>
                      {entryCellsData.map((entryCell, index) => {
                        const text = `Category: ${category.name}\n${
                          DAILY_QUIZ_DIFFICULTY_LEVELS[index]
                            ? `Difficulty: ${DAILY_QUIZ_DIFFICULTY_LEVELS[index].text}\n`
                            : ""
                        }`;
                        return (
                          <td key={index} className="px-6 py-3">
                            <div className="grid grid-cols-2 border rounded-xl">
                              <div
                                title={text + "Status: Enabled"}
                                className=" cursor-pointer hover:bg-slate-100 text-[#31d496] font-[500] border-r-[1px] border-b-[1px] rounded-tl-xl "
                              >
                                {entryCell?.activeCount || 0}
                              </div>
                              <div
                                title={text + "Status: Disabled"}
                                className=" cursor-pointer hover:bg-slate-100 text-[#da7878] font-[300] border-b-[1px] rounded-tl-xl "
                              >
                                {entryCell?.inactiveCount || 0}
                              </div>
                              <div
                                title={text}
                                className=" cursor-pointer hover:bg-slate-100 col-span-2"
                              >
                                {(entryCell?.activeCount || 0) +
                                  (entryCell?.inactiveCount || 0)}
                              </div>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default QuestionStats;

function segregateData(data) {
  const result = [];

  data.forEach((item) => {
    const { categoryId, difficultyLevel, status, count } = item;
    let category = result.find(
      (c) =>
        c.categoryId === categoryId && c.difficultyLevel === difficultyLevel
    );

    if (!category) {
      category = {
        categoryId,
        difficultyLevel,
        activeCount: 0,
        inactiveCount: 0,
      };
      result.push(category);
    }

    if (status === 0) {
      category.activeCount += count;
    } else if (status === 1) {
      category.inactiveCount += count;
    }
  });
  return result;
}
