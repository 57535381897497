import React from "react";
import { Link } from "react-router-dom";

const DailyQuizPage = ({ sideBarComponents }) => {
  return (
    <div className="">
      <h1 className="text-center text-[#303c4e] text-[3em]">Daily Quiz</h1>
      {/* Sidebar for Live Quiz Sub-options */}
      <section className="p-4  flex justify-center mt-14">
        <div className="grid grid-cols-1  tablet:grid-cols-2 laptop:grid-cols-3 gap-x-14 gap-y-12 ">
          {sideBarComponents &&
            Array.isArray(sideBarComponents) &&
            sideBarComponents.map((sideBarComponent) => (
              <Link
                key={sideBarComponent.text}
                to={sideBarComponent.to}
                className="py-6 flex flex-col gap-y-6 items-center text-[2em] font-semibold text-gray-800 hover:text-white bg-gray-300 size-60 mobile:size-72 hover:bg-gray-400 rounded box-shadow-cards"
              >
                {sideBarComponent.text}
                <img
                  src={sideBarComponent.image}
                  className="mobile:size-48 size-36"
                  alt=""
                />
              </Link>
            ))}
        </div>
      </section>
    </div>
  );
};

export default DailyQuizPage;
