import { TOKEN_KEY } from "../constants/SessionStorageKeys"

const getValue = (key) => {
    const tokenStr = sessionStorage.getItem(key);
    if(!tokenStr){
        return null;
    }
    return JSON.parse(tokenStr);
}

const saveValue = (key, value) => {
    const valStr = JSON.stringify(value);
    sessionStorage.setItem(key, valStr);
}

// TOKEN
export const getToken = () => {
    return getValue(TOKEN_KEY);
}

export const saveToken = (value) => {
    saveValue(TOKEN_KEY, value);
}

export const deleteToken = () => {
    sessionStorage.removeItem(TOKEN_KEY);
}