// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-shadow-cards {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.tableFixHead          { overflow: auto; height: 70vh; }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; }

/* Just common table stuff. Really. */
table  { border-collapse: collapse; width: 100%; }
th     { background:rgb(249, 250, 251); }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;uCACqC;AACvC;;AAEA;EACE;uCACqC;AACvC;;AAEA,yBAAyB,cAAc,EAAE,YAAY,EAAE;AACvD,yBAAyB,gBAAgB,EAAE,MAAM,EAAE,UAAU,EAAE;;AAE/D,qCAAqC;AACrC,SAAS,yBAAyB,EAAE,WAAW,EAAE;AACjD,SAAS,6BAA6B,EAAE","sourcesContent":[".box-shadow-cards {\n  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,\n    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;\n}\n\n.box-shadow {\n  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,\n    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;\n}\n\n.tableFixHead          { overflow: auto; height: 70vh; }\n.tableFixHead thead th { position: sticky; top: 0; z-index: 1; }\n\n/* Just common table stuff. Really. */\ntable  { border-collapse: collapse; width: 100%; }\nth     { background:rgb(249, 250, 251); }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
