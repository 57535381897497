import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useGetLiveQuizStats from "../../../hooks/liveQuiz/useGetLiveQuizStats";

const LiveQuizStats = () => {
  const { id: liveQuizId } = useParams();
  const { getLiveQuizStats, loading } = useGetLiveQuizStats();
  const [pageNo, setPageNo] = useState(0);
  const [pageSize] = useState(10);
  const [msisdn, setMsisdn] = useState("");
  const [entries, setEntries] = useState([]);
  const [maxPages, setMaxPages] = useState(0);

  const fetchStats = () => {
    getLiveQuizStats(liveQuizId, pageNo, pageSize, msisdn, (data) => {
      if (Array.isArray(data.content)) {
        setEntries(data.content);
      }
      setMaxPages(Math.max(+data?.totalPages, 1));
    });
  };

  useEffect(
    fetchStats,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [liveQuizId, pageNo, pageSize]
  );

  const handleSearch = (e) => {
    e.preventDefault();
    if (pageNo === 0) {
      fetchStats();
    } else {
      setPageNo(0);
    }
  };

  return loading ? (
    <div className="text-3xl text-center">
      Loading Stats... <br />
      {`Live Quiz Id: ${liveQuizId}, Page: ${pageNo + 1}`}
    </div>
  ) : (
    <div className="space-y-20 text-[1.6em]">
      <form
        onSubmit={handleSearch}
        className="flex justify-between items-center gap-10 "
      >
        <input
          type="text"
          value={msisdn}
          onChange={(e) => {
            if (!isNaN(e.target.value) && !e.target.value.includes("-")) {
              setMsisdn(e.target.value);
            }
          }}
          maxLength={12}
          placeholder="Search by MSISDN"
          className="border-2 border-gray-300 p-2 flex-grow rounded-md px-5 py-3"
        />
        <input
          className="px-5 py-3 bg-blue-800 text-lime-200 rounded-md cursor-pointer hover:scale-105 transform transition duration-300 ease-in-out"
          type="submit"
          value={"Search"}
        />
      </form>

      <div className="space-y-5">
        <div className="flex justify-end items-center gap-5">
          <button
            className="px-3 py-2 bg-gray-500 disabled:bg-slate-400 rounded-md text-lime-200"
            onClick={() => setPageNo((prev) => prev - 1)}
            disabled={pageNo === 0}
          >
            &laquo; Previous
          </button>
          <div className="pointer-events-none text-bold text-xl ">{`${
            pageNo + 1
          }/${maxPages}`}</div>
          <button
            className="px-3 py-2 bg-gray-500 disabled:bg-slate-400 rounded-md text-lime-200"
            onClick={() => setPageNo((prev) => prev + 1)}
            disabled={pageNo === maxPages - 1 || entries.length < pageSize}
          >
            Next &raquo;
          </button>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase ">
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Msisdn
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  User Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Score
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Correct Answers
                </th>
                <th scope="col" className="px-6 py-3">
                  Incorrect Answers
                </th>
              </tr>
            </thead>
            <tbody>
              {entries.map((entry) => {
                return (
                  <tr key={entry.itemId} className="border-b border-gray-200 ">
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50">
                      {entry?.msisdn}
                    </td>
                    <td className="px-6 py-4">{entry?.userName}</td>
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50">
                      {entry?.totalScore}
                    </td>
                    <td className="px-6 py-4">{entry?.correctAnswers}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {entry?.incorrectAnswers}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LiveQuizStats;
