import React, { useState } from "react";
import useUploadCSVFile from "../../../hooks/staticQuiz/useUploadCSVFile";
import { useNavigate } from "react-router-dom";
import { DailyQuizRoute } from "../../../constants/RouteConstants";

const UploadQuestion = () => {
  const [file, setFile] = useState(null);
  const { uploadCSVFile, uploading } = useUploadCSVFile();
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFile(event.dataTransfer.files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    uploadCSVFile(file, () => navigate(DailyQuizRoute));
  };

  return (
    <div className="flex items-center justify-center h-full text-[1.6em]">
      <div className="bg-gray-100 shadow-md rounded-lg p-8 max-w-md w-full">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Upload CSV File
        </h2>
        <form onSubmit={handleSubmit}>
          <div
            className="mb-6 border-4 border-dashed border-gray-300 rounded-lg p-8 flex flex-col items-center justify-center text-gray-600 cursor-pointer"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <label
              className="block text-center text-gray-700 text-sm font-bold mb-2 cursor-pointer"
              htmlFor="file"
            >
              Drag and drop your CSV file here, or click to select a file
            </label>
            <input
              type="file"
              id="file"
              accept=".csv"
              onChange={handleFileChange}
              className="hidden"
            />
            {file && (
              <p className="mt-4 text-gray-700">Selected file: {file.name}</p>
            )}
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={uploading}
          >
            {uploading ? (
              <div className="flex items-center justify-center">
                <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
                Uploading...
              </div>
            ) : (
              "Upload"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UploadQuestion;
