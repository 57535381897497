export const LIVE_QUIZ_STATUS_CONSTANT_SCHEDULED = 0;
export const LIVE_QUIZ_STATUS_CONSTANT_SCHEDULED_TEXT = "SCHEDULED";
export const LIVE_QUIZ_STATUS_CONSTANT_LIVE = 1;
export const LIVE_QUIZ_STATUS_CONSTANT_LIVE_TEXT = "LIVE";
export const LIVE_QUIZ_STATUS_CONSTANT_ENDED = 2;
export const LIVE_QUIZ_STATUS_CONSTANT_ENDED_TEXT = "ENDED";
export const LIVE_QUIZ_STATUS_CONSTANT_CANCELLED = 3;
export const LIVE_QUIZ_STATUS_CONSTANT_CANCELLED_TEXT = "CANCELLED";
export const LIVE_QUIZ_STATUS_CONSTANTS = [
  {
    text: LIVE_QUIZ_STATUS_CONSTANT_SCHEDULED_TEXT,
    value: LIVE_QUIZ_STATUS_CONSTANT_SCHEDULED,
  },
  {
    text: LIVE_QUIZ_STATUS_CONSTANT_LIVE_TEXT,
    value: LIVE_QUIZ_STATUS_CONSTANT_LIVE,
  },
  {
    text: LIVE_QUIZ_STATUS_CONSTANT_ENDED_TEXT,
    value: LIVE_QUIZ_STATUS_CONSTANT_ENDED,
  },
  {
    text: LIVE_QUIZ_STATUS_CONSTANT_CANCELLED_TEXT,
    value: LIVE_QUIZ_STATUS_CONSTANT_CANCELLED,
  },
];

