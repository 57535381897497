export const DAILY_QUIZ_DIFFICULTY_LEVEL_EASY = 1;
export const DAILY_QUIZ_DIFFICULTY_LEVEL_EASY_TEXT = "Easy";
export const DAILY_QUIZ_DIFFICULTY_LEVEL_MEDIUM = 2;
export const DAILY_QUIZ_DIFFICULTY_LEVEL_MEDIUM_TEXT = "Medium";
export const DAILY_QUIZ_DIFFICULTY_LEVEL_HARD = 3;
export const DAILY_QUIZ_DIFFICULTY_LEVEL_HARD_TEXT = "Hard";
export const DAILY_QUIZ_DIFFICULTY_LEVELS = [
  {
    text: DAILY_QUIZ_DIFFICULTY_LEVEL_EASY_TEXT,
    value: DAILY_QUIZ_DIFFICULTY_LEVEL_EASY,
  },
  {
    text: DAILY_QUIZ_DIFFICULTY_LEVEL_MEDIUM_TEXT,
    value: DAILY_QUIZ_DIFFICULTY_LEVEL_MEDIUM,
  },
  {
    text: DAILY_QUIZ_DIFFICULTY_LEVEL_HARD_TEXT,
    value: DAILY_QUIZ_DIFFICULTY_LEVEL_HARD,
  },
];
