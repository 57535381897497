import React, { useState, useEffect, useContext } from "react";
import useLogin from "../hooks/auth/useLogin";
import "react-toastify/dist/ReactToastify.css";
import { appContext } from "../context/context";
import { Navigate } from "react-router-dom";
import { DashboardRoute } from "../constants/RouteConstants";

const LoginForm = () => {
  const { isLoggedIn } = useContext(appContext);
  const savedCredentials = JSON.parse(localStorage.getItem("credentials"));
  const autoLogin = JSON.parse(localStorage.getItem("autoLogin"));
  const { login, loading } = useLogin();
  const [username, setUsername] = useState(savedCredentials?.username || "");
  const [password, setPassword] = useState(savedCredentials?.password || "");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (savedCredentials && autoLogin) {
      login(username, password, rememberMe);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    login(username, password, rememberMe);
  };

  const handleReset = (e) => {
    e.preventDefault();
    setUsername("");
    setPassword("");
    localStorage.removeItem("credentials");
    localStorage.removeItem("autoLogin");
  };

  return isLoggedIn ? (
    <Navigate to={DashboardRoute} replace />
  ) : (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-[3.2em] rounded shadow-md w-full max-w-md">
        <h1 className="text-[3.6em] font-bold mb-[1.6em] text-center">
          Quiz Wiz Admin
        </h1>
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <div className="mb-[1.6em]">
            <label className="block text-[1.8em] font-medium text-gray-700">
              Username
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="mt-[0.4em] block w-full px-[1.2em] py-[0.8em] bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 mobile:text-[1.8em]"
              required
            />
          </div>
          <div className="mb-[1.6em]">
            <label className="block text-[1.8em] font-medium text-gray-700">
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-[0.4em] block w-full px-[1.2em] py-[0.8em] bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 mobile:text-[1.8em]"
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 px-[1.2em] py-[0.8em] text-gray-500"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
          </div>
          <div className="flex items-center mb-[1.6em]">
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              className="h-[1.6em] w-[1.6em] text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label className="ml-[0.8em] block text-[1.8em] text-gray-900">
              Keep me logged in
            </label>
          </div>
          <button
            type="submit"
            disabled={loading}
            className="w-full py-[0.8em] px-[1.6em] border border-transparent rounded-md shadow-sm text-[1.8em] font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-[0.8em] focus:ring-offset-[0.8em] focus:ring-blue-500"
          >
            {loading ? "Loading..." : "Login"}
          </button>
          <button
            type="reset"
            disabled={loading}
            className={`w-full mt-[0.8em] py-[0.8em] px-[1.6em] border border-transparent rounded-md shadow-sm text-[1.8em] font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none`}
          >
            Clear
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
