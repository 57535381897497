import { toast } from "react-toastify";

const useHandleError = () => {
  const handleError = (error, id) => {
    console.log(error);
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data ||
      error?.message ||
      error;

    if (id) {
      toast.update(id, {
        render: errorMessage || "Error",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    } else {
      toast.error(errorMessage, { autoClose: 1000 });
    }
  };
  return handleError;
};

export default useHandleError;
