import { useContext } from "react";
import useHandleError from "../useHandleError";
import { appContext } from "../../context/context";
import {
  BASE_URL,
  ENDPOINT_GET_ALL_LIVE_QUIZ,
} from "../../constants/APIConstant";
import axios from "axios";

const useGetLiveQuizzes = () => {
  const handleError = useHandleError();
  const { token } = useContext(appContext);
  const getLiveQuizzes = (page, pageSize, callback) => {
    axios
      .request({
        method: "GET",
        url: BASE_URL + ENDPOINT_GET_ALL_LIVE_QUIZ,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: page,
          size: pageSize,
        },
      })
      .then((response) => {
        callback(response?.data);
      })
      .catch(handleError);
  };
  return getLiveQuizzes;
};

export default useGetLiveQuizzes;
