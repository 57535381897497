import { useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import useHandleError from "../useHandleError";
import { appContext } from "../../context/context";
import {
  BASE_URL,
  ENDPOINT_UPLOAD_CSV_FILE,
} from "../../constants/APIConstant";

const useUploadCSVFile = () => {
  const handleError = useHandleError();
  const { token } = useContext(appContext);
  const [uploading, setUploading] = useState(false);

  const uploadCSVFile = (file, callback) => {
    const id = toast.loading("Uploading...");
    const formData = new FormData();
    formData.append("file", file);

    setUploading(true);

    axios
      .request({
        method: "POST",
        url: BASE_URL + ENDPOINT_UPLOAD_CSV_FILE,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      })
      .then((response) => {
        callback(response?.data);
        toast.update(id, {
          render: response?.data?.message || "File uploaded successfully!",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
      })
      .catch((error) => {
        handleError(error, id);
      })
      .finally(() => setUploading(false));
  };

  return { uploadCSVFile, uploading };
};

export default useUploadCSVFile;
