import { useContext, useState } from "react";
import useHandleError from "../useHandleError";
import { appContext } from "../../context/context";
import {
  BASE_URL,
  ENDPOINT_GET_ALL_CATEGORIES,
} from "../../constants/APIConstant";
import axios from "axios";

const useGetAllCategories = () => {
  const handleError = useHandleError();
  const { token } = useContext(appContext);
  const [fetchingCategories, setFetchingCategories] = useState(false);
  const getAllCategories = (callback) => {
    setFetchingCategories(true);
    axios
      .request({
        method: "GET",
        url: BASE_URL + ENDPOINT_GET_ALL_CATEGORIES,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        callback(response?.data);
      })
      .catch(handleError)
      .finally(() => setFetchingCategories(false));
  };

  return { getAllCategories, fetchingCategories };
};

export default useGetAllCategories;
