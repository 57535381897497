import { useContext, useState } from "react";
import useHandleError from "../useHandleError";
import { appContext } from "../../context/context";
import {
  BASE_URL,
  ENDPOINT_GET_LIVE_QUIZ_STATS,
} from "../../constants/APIConstant";
import axios from "axios";

const useGetLiveQuizStats = () => {
  const [loading, setLoading] = useState(false);
  const handleError = useHandleError();
  const { token } = useContext(appContext);
  const getLiveQuizStats = (id, page, size, msisdn, callback) => {
    setLoading(true);
    axios
      .request({
        method: "GET",
        url: BASE_URL + ENDPOINT_GET_LIVE_QUIZ_STATS,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          id,
          page,
          size,
          msisdn,
        },
      })
      .then((response) => {
        callback(response?.data);
      })
      .catch(handleError).finally(setLoading(false));
  };
  return { getLiveQuizStats, loading };
};

export default useGetLiveQuizStats;
