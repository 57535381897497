import React, { useState, useEffect } from "react";
import useGetAllCategories from "../../../hooks/staticQuiz/useGetAllCategories";
import useGetStaticQuizQuestions from "../../../hooks/staticQuiz/useGetStaticQuizQuestions";
import useCountStaticQuizQuestions from "../../../hooks/staticQuiz/useCountStaticQuizQuestions";
import useAddStaticQuizQuestion from "../../../hooks/staticQuiz/useAddStaticQuizQuestion";
import useUpdateStaticQuizQuestion from "../../../hooks/staticQuiz/useUpdateStaticQuizQuestion";
import {
  DAILY_QUIZ_DIFFICULTY_LEVELS,
  DAILY_QUIZ_DIFFICULTY_LEVEL_EASY,
  DAILY_QUIZ_DIFFICULTY_LEVEL_HARD,
  DAILY_QUIZ_DIFFICULTY_LEVEL_MEDIUM,
} from "../../../constants/DailyQuizDifficultyLevelConstants";
import {
  DAILY_QUIZ_QUESTION_STATUS_CONSTANTS,
  DAILY_QUIZ_QUESTION_STATUS_CONSTANT_ACTIVE,
  DAILY_QUIZ_QUESTION_STATUS_CONSTANT_INACTIVE,
} from "../../../constants/DailyQuizQuestionStatusConstants";
import {
  DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_ACTIVE,
  DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_INACTIVE,
} from "../../../constants/DailyQuizCategoryStatusConstants";
import documentUploadIcon from "../../../assets/svgs/document-upload-5775.svg";
import searchIcon from "../../../assets/svgs/search-2904.svg";
import clearFilterIcon from "../../../assets/svgs/filter.svg";
import plusIcon from "../../../assets/svgs/green-plus-11975.svg";
import editIcon from "../../../assets/svgs/pencil-writing-blue-circle-20625.svg";
import backArrowIcon from "../../../assets/svgs/back-arrow.svg";
import doubleBackArrowIcon from "../../../assets/svgs/double-back-arrow.svg";
import nextArrowIcon from "../../../assets/svgs/next-arrow.svg";
import doubleNextArrowIcon from "../../../assets/svgs/double-next-arrow-.svg";
import { Link, useSearchParams } from "react-router-dom";
import { UplaodQuestionsFileRoute } from "../../../constants/RouteConstants";

const QuestionScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { getAllCategories, fetchingCategories } = useGetAllCategories();
  const { getStaticQuizQuestions, fetchingQuestions } =
    useGetStaticQuizQuestions();
  const { countStaticQuizQuestions, fetchingCount } =
    useCountStaticQuizQuestions();
  const { addStaticQuizQuestion, saving } = useAddStaticQuizQuestion();
  const { updateStaticQuizQuestion, updating } = useUpdateStaticQuizQuestion();
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [totalQuestions, setTotalQuestions] = useState(0);
  // const [page, setPage] = useState(0);
  const pageSize = 10;
  const defaultFilterObj = {
    questionFilter: "",
    categoryId: "",
    difficultyLevel: "",
    status: "",
  };
  const [filter, setFilter] = useState(defaultFilterObj);
  const [tempFilter, setTempFilter] = useState(defaultFilterObj);
  const [isEditing, setIsEditing] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const detaultQuestion = {
    question: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    categoryId: "",
    correctOptionNo: 1,
    difficultyLevel: 1,
  };
  const [currentQuestion, setCurrentQuestion] = useState(detaultQuestion);

  // Retrieve the 'p' parameter
  const page = +searchParams.get("p") || null;
  const setPage = (value) => setSearchParams({ p: value });

  // Set 'p' to 0 if it is null
  useEffect(() => {
    if (!page || page < 0) {
      setSearchParams({ p: "0" });
    }
  }, [page, setSearchParams]);

  useEffect(() => {
    getAllCategories(setCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchQuestions, [page, pageSize]);
  useEffect(() => {
    page ? setPage(0) : fetchQuestionsForPage(0);
    countStaticQuizQuestions(filter, setTotalQuestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const fetchQuestionsForPage = (pageNo) =>
    getStaticQuizQuestions(filter, pageNo, pageSize, setQuestions);
  const fetchQuestions = () => {
    fetchQuestionsForPage(page);
  };

  const handleTempFilterChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setTempFilter((values) => ({ ...values, [name]: value }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentQuestion((prevData) => ({
      ...prevData,
      [name]: isNaN(value) ? value : parseInt(value),
    }));
  };

  const handleSubmit = (e) => {
    const callback = () => {
      fetchQuestions();
      resetForm();
    };
    e.preventDefault();
    if (isEditing) {
      updateStaticQuizQuestion(
        {
          id: currentQuestion?.id,
          question: currentQuestion?.question,
          option1: currentQuestion?.option1,
          option2: currentQuestion?.option2,
          option3: currentQuestion?.option3,
          option4: currentQuestion?.option4,
          correctOptionNo: currentQuestion?.correctOptionNo,
          categoryId: currentQuestion?.categoryId,
          difficultyLevel: currentQuestion?.difficultyLevel,
          status: currentQuestion?.status,
        },
        callback
      );
    } else {
      addStaticQuizQuestion(
        {
          question: currentQuestion?.question,
          option1: currentQuestion?.option1,
          option2: currentQuestion?.option2,
          option3: currentQuestion?.option3,
          option4: currentQuestion?.option4,
          correctOptionNo: currentQuestion?.correctOptionNo,
          categoryId: currentQuestion?.categoryId,
          difficultyLevel: currentQuestion?.difficultyLevel,
        },
        callback
      );
    }
  };

  const noOfPages = Math.ceil(totalQuestions / pageSize);

  const handleEdit = (question) => {
    setCurrentQuestion(question);
    setIsEditing(true);
    setShowForm(true);
  };

  const resetForm = () => {
    setCurrentQuestion(detaultQuestion);
    setIsEditing(false);
    setShowForm(false);
  };

  const entryCellClass = "py-2 px-4 border-b";

  const questionForm = (
    <div className="mt-8">
      <h2 className="text-xl font-bold mb-4">
        {isEditing ? "Edit" : "Add"} Question
      </h2>
      <form
        onSubmit={handleSubmit}
        className="bg-white p-4 rounded shadow space-y-4"
      >
        <div>
          <input
            type="text"
            name="question"
            placeholder="Question"
            value={currentQuestion.question}
            onChange={handleInputChange}
            required
            className="border p-2 w-full rounded"
          />
        </div>
        <div className="grid grid-cols-2 gap-x-5 gap-y-10">
          {[1, 2, 3, 4].map((option) => (
            <div key={option} className={`flex items-center gap-3`}>
              <input
                type="radio"
                name="correctOptionNo"
                value={option}
                checked={currentQuestion.correctOptionNo === option}
                onChange={handleInputChange}
                className="size-5"
              />
              <input
                type="text"
                name={`option${option}`}
                placeholder={`Option ${option}`}
                value={currentQuestion?.[`option${option}`]}
                onChange={handleInputChange}
                required
                className="border p-2 w-full rounded"
              />
            </div>
          ))}
        </div>
        <div>
          <select
            name="categoryId"
            value={currentQuestion.categoryId}
            onChange={handleInputChange}
            required
            className="border p-2 w-full rounded"
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option
                key={category.id}
                value={category.id}
                className={` ${
                  category?.status ===
                  DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_ACTIVE
                    ? "text-[#31d496] font-[500]"
                    : category?.status ===
                      DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_INACTIVE
                    ? "text-[#da7878] font-[300]"
                    : ""
                } `}
              >
                {`${category.name} (${category?.id})`}
              </option>
            ))}
          </select>
        </div>
        <div className="grid grid-cols-2 gap-x-5">
          <div className="flex gap-10 items-center">
            <label className="">Difficulty:</label>
            {DAILY_QUIZ_DIFFICULTY_LEVELS.map((difficultyLevel) => (
              <label
                key={difficultyLevel?.value}
                className="flex items-center gap-2"
              >
                <input
                  type="radio"
                  name="difficultyLevel"
                  value={parseInt(difficultyLevel?.value)}
                  checked={
                    currentQuestion.difficultyLevel === difficultyLevel?.value
                  }
                  onChange={handleInputChange}
                  className="size-[1.2em]"
                />
                {difficultyLevel?.text}
              </label>
            ))}
          </div>
          {isEditing && (
            <label className="select-none flex items-center">
              <input
                type="checkbox"
                name="status"
                checked={
                  currentQuestion?.status ===
                  DAILY_QUIZ_QUESTION_STATUS_CONSTANT_ACTIVE
                }
                onChange={(e) =>
                  handleInputChange({
                    target: {
                      name: "status",
                      value: e.target.checked
                        ? DAILY_QUIZ_QUESTION_STATUS_CONSTANT_ACTIVE
                        : DAILY_QUIZ_QUESTION_STATUS_CONSTANT_INACTIVE,
                    },
                  })
                }
                className="mr-2 size-5"
              />
              Enabled
            </label>
          )}
        </div>
        <div className="flex justify-between">
          <button
            type="button"
            onClick={resetForm}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={saving || updating}
            className="bg-green-500 text-white px-4 py-2 rounded"
          >
            {isEditing ? "Update" : "Add"} Question
          </button>
        </div>
      </form>
    </div>
  );

  const fetchingData = fetchingCategories || fetchingQuestions || fetchingCount;

  const questionsTable = (
    <div className="space-y-4">
      <div className="flex items-center justify-end gap-3">
        <Link
          to={UplaodQuestionsFileRoute}
          className="p-1 rounded-lg hover:bg-slate-400 hover:bg-opacity-20"
          title="Upload CSV"
        >
          <img className="size-7" src={documentUploadIcon} alt="Upload CSV" />
        </Link>
        <button
          onClick={() => setShowForm(true)}
          className="p-2 rounded-lg hover:bg-slate-400 hover:bg-opacity-20"
          title="Add Question"
        >
          <img className="size-5" src={plusIcon} alt="Add Question" />
        </button>
      </div>
      <div className="flex justify-between gap-3 items-start">
        <div className="w-full space-y-4 border-[1px] p-3 rounded-xl">
          <div className="w-full">
            <input
              type="text"
              placeholder="Search questions"
              name="questionFilter"
              value={tempFilter?.questionFilter}
              onChange={handleTempFilterChange}
              className="border p-2 w-full rounded"
            />
          </div>
          <div className="grid grid-cols-4">
            <div className="flex gap-3">
              <label htmlFor="categoryIdSearch">Category: </label>
              <select
                className="min-w-[70%]"
                name="categoryId"
                id="categoryIdSearch"
                value={tempFilter?.categoryId}
                onChange={handleTempFilterChange}
              >
                <option value={""}>All</option>
                {categories.map((category) => (
                  <option
                    className={`${
                      category.status ===
                      DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_INACTIVE
                        ? "text-[#da7878] font-[300]"
                        : "text-[#31d496] font-[500]"
                    }`}
                    key={category?.id}
                    value={category?.id}
                  >
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex gap-3">
              <label htmlFor="difficultyLevelSearch">Difficulty: </label>
              <select
                className="min-w-[70%]"
                name="difficultyLevel"
                id="difficultyLevelSearch"
                value={tempFilter?.difficultyLevel}
                onChange={handleTempFilterChange}
              >
                <option value={""}>All</option>
                {DAILY_QUIZ_DIFFICULTY_LEVELS.map((level) => (
                  <option
                    className={`${
                      level.value === DAILY_QUIZ_DIFFICULTY_LEVEL_EASY
                        ? "text-[#4CAF50]"
                        : level.value === DAILY_QUIZ_DIFFICULTY_LEVEL_MEDIUM
                        ? "text-[#FFC107]"
                        : level.value === DAILY_QUIZ_DIFFICULTY_LEVEL_HARD
                        ? "text-[#F44336]"
                        : "text-gray-500"
                    }`}
                    key={level.value}
                    value={level.value}
                  >
                    {level.text}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex gap-3">
              <label htmlFor="statusSearch">Status: </label>
              <select
                className="min-w-[70%]"
                name="status"
                id="statusSearch"
                value={tempFilter?.status}
                onChange={handleTempFilterChange}
              >
                <option value={""}>All</option>
                {DAILY_QUIZ_QUESTION_STATUS_CONSTANTS.map((status) => (
                  <option
                    className={`${
                      status.value ===
                      DAILY_QUIZ_QUESTION_STATUS_CONSTANT_ACTIVE
                        ? "text-[#31d496] font-[500]"
                        : status.value ===
                          DAILY_QUIZ_QUESTION_STATUS_CONSTANT_INACTIVE
                        ? "text-[#da7878] font-[300]"
                        : ""
                    }`}
                    key={status.value}
                    value={status.value}
                  >
                    {status.text}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-3 mt-1">
          <button
            onClick={() => setFilter(tempFilter)}
            className="p-2 rounded-lg hover:bg-slate-400 hover:bg-opacity-20"
            title="Search"
          >
            <img className="size-5" src={searchIcon} alt="Search" />
          </button>
          <button
            onClick={() => {
              setTempFilter(defaultFilterObj);
              setFilter(defaultFilterObj);
            }}
            className="p-2 rounded-lg hover:bg-slate-400 hover:bg-opacity-20"
            title="Clear Filter"
          >
            <img className="size-5" src={clearFilterIcon} alt="Clear Filter" />
          </button>
        </div>
      </div>
      <div className="flex justify-between items-center w-[25%] mx-auto">
        <button
          onClick={() => {
            if (page > 0) {
              setPage(0);
            }
          }}
          disabled={page < 1 || fetchingData}
          className="bg-gray-500 text-white px-2 py-1 rounded disabled:opacity-50"
          title="First"
        >
          <img
            className="size-5 rounded-xl"
            src={doubleBackArrowIcon}
            alt="First"
          />
        </button>
        <button
          onClick={() => {
            if (page > 0) {
              setPage(page - 1);
            }
          }}
          disabled={page < 1 || fetchingData}
          className="bg-gray-500 text-white px-2 py-1 rounded disabled:opacity-50"
          title="Previous"
        >
          <img
            className="size-5 rounded-xl"
            src={backArrowIcon}
            alt="Previous"
          />
        </button>
        <div className="pointer-events-none">
          {noOfPages > 0 ? <>
            {page + 1}/{noOfPages}
          </>
          : <span>0</span>
        }
        </div>
        <button
          onClick={() => setPage(page + 1)}
          disabled={page + 1 >= noOfPages || fetchingData}
          className="bg-gray-500 text-white px-2 py-1 rounded disabled:opacity-50"
          title="Next"
        >
          <img className="size-5 rounded-xl" src={nextArrowIcon} alt="Next" />
        </button>
        <button
          onClick={() => setPage(noOfPages - 1)}
          disabled={page + 1 >= noOfPages || fetchingData}
          className="bg-gray-500 text-white px-2 py-1 rounded disabled:opacity-50"
          title="Last"
        >
          <img
            className="size-5 rounded-xl"
            src={doubleNextArrowIcon}
            alt="Last"
          />
        </button>
      </div>
      {fetchingData ? (
        <div className="my-10 ">Loading..</div>
      ) : questions.length > 0 ? (
        <table className="min-w-full bg-white border">
          <thead>
            <tr className="w-full bg-gray-200">
              <th className={` ${entryCellClass} text-left`}>Question</th>
              <th className={` ${entryCellClass} `}>Category</th>
              <th className={` ${entryCellClass} `}>Difficulty</th>
              <th className={` ${entryCellClass} `}>Status</th>
              <th className={` ${entryCellClass} `}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {questions.map((question) => {
              const questionCategory = categories.find(
                (cat) => cat.id === question.categoryId
              );
              return (
                <tr key={question.id} className="hover:bg-gray-100">
                  <td
                    className={` ${entryCellClass} text-left w-[30vw] tablet:w-[40vw] text-wrap`}
                  >
                    {question.question}
                  </td>
                  <td
                    className={` ${entryCellClass} ${
                      questionCategory.status ===
                      DAILY_QUIZ_CATEGORY_STATUS_CONSTANT_INACTIVE
                        ? "text-[#da7878] font-[300]"
                        : ""
                    }`}
                  >
                    {questionCategory?.name}
                  </td>
                  <td
                    className={` ${entryCellClass} ${
                      question?.difficultyLevel ===
                      DAILY_QUIZ_DIFFICULTY_LEVEL_EASY
                        ? "text-[#4CAF50]"
                        : question?.difficultyLevel ===
                          DAILY_QUIZ_DIFFICULTY_LEVEL_MEDIUM
                        ? "text-[#FFC107]"
                        : question?.difficultyLevel ===
                          DAILY_QUIZ_DIFFICULTY_LEVEL_HARD
                        ? "text-[#F44336]"
                        : "text-gray-500"
                    }`}
                  >
                    {DAILY_QUIZ_DIFFICULTY_LEVELS.find(
                      (level) => question?.difficultyLevel === level?.value
                    )?.text || "Unknown"}
                  </td>
                  <td
                    className={` ${entryCellClass} ${
                      question?.status ===
                      DAILY_QUIZ_QUESTION_STATUS_CONSTANT_ACTIVE
                        ? "text-[#31d496] font-[500]"
                        : question?.status ===
                          DAILY_QUIZ_QUESTION_STATUS_CONSTANT_INACTIVE
                        ? "text-[#da7878] font-[300]"
                        : ""
                    }`}
                  >
                    {DAILY_QUIZ_QUESTION_STATUS_CONSTANTS.find(
                      (status) => question?.status === status?.value
                    )?.text || "Unknown"}
                  </td>
                  <td className={` ${entryCellClass} `}>
                    <button onClick={() => handleEdit(question)} title="Edit">
                      <img className="size-5" src={editIcon} alt="Edit" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="my-10 ">No Questions Found..</div>
      )}
    </div>
  );

  return (
    <div className="container mx-auto p-4 text-center text-[1.6em]">
      <h1 className="text-2xl font-bold mb-4">Questions</h1>
      {showForm ? questionForm : questionsTable}
    </div>
  );
};

export default QuestionScreen;
