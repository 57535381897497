import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  EditLiveQuizzesRouteTitleIdentifier,
  LiveQuizRoute,
  SeeLiveQuizQuestionsRoute,
} from "../../../constants/RouteConstants";
import useUpdateLiveQuiz from "../../../hooks/liveQuiz/useUpdateLiveQuiz";

const EditLiveQuiz = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { updateLiveQuiz, loading } = useUpdateLiveQuiz();
  const quiz = location?.state?.quiz;

  // Initialize state with quiz data
  const [formData, setFormData] = useState({
    id: quiz?.id,
    title: "",
    description: "",
    prizeText: "",
    scheduledTimestamp: "",
  });

  useEffect(() => {
    if (quiz) {
      setFormData({
        id: quiz?.id,
        title: quiz.name || "",
        description: quiz.description || "",
        prizeText: quiz.prizeText || "",
        scheduledTimestamp:
          quiz?.scheduleDate && quiz?.scheduledTime
            ? `${quiz?.scheduleDate}T${quiz?.scheduledTime}`
            : "",
      });
    }
  }, [quiz]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleScheduledTimestampChange = (e) => {
    const value = e.target.value;
    const now = new Date().toISOString().slice(0, 16);
    if (value >= now) {
      setFormData((prevData) => ({
        ...prevData,
        scheduledTimestamp: `${value}:00`,
      }));
    } else {
      alert("Please select a future date and time.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateLiveQuiz(formData, () => navigate(LiveQuizRoute));
  };

  return (
    <div className="max-w-4xl text-[1.6em] mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold mb-6 text-center">Edit Live Quiz</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid gap-6 grid-cols-1 md:grid-cols-2">
          <div className="flex flex-col">
            <label
              htmlFor="title"
              className="text-lg font-semibold text-gray-800 mb-2"
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData?.title}
              onChange={handleChange}
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="description"
              className="text-lg font-semibold text-gray-800 mb-2"
            >
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={formData?.description}
              onChange={handleChange}
              rows="4"
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="prizeText"
              className="text-lg font-semibold text-gray-800 mb-2"
            >
              Prize Text
            </label>
            <input
              type="text"
              id="prizeText"
              name="prizeText"
              value={formData?.prizeText}
              onChange={handleChange}
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="scheduledTimestamp"
              className="text-lg font-semibold text-gray-800 mb-2"
            >
              Scheduled Timestamp
            </label>
            <input
              type="datetime-local"
              id="scheduledTimestamp"
              name="scheduledTimestamp"
              min={new Date()
                .toISOString()
                .slice(0, new Date().toISOString().lastIndexOf(":"))}
              value={formData?.scheduledTimestamp}
              onChange={handleScheduledTimestampChange}
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>
        </div>

        <div className="flex justify-between">
          <Link
            to={SeeLiveQuizQuestionsRoute.replace(
              EditLiveQuizzesRouteTitleIdentifier,
              quiz?.id
            )}
            state={{ quiz }}
            className="px-6 py-3 bg-gray-600 text-white rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            See Questions
          </Link>
          <button
            type="submit"
            disabled={loading}
            className="px-6 py-3 bg-indigo-600 text-white rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            {loading ? "Saving" : "Save Changes"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditLiveQuiz;
